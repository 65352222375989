import MarathonRoute from './home-module/MarathonRoute';


function App() {
  return (
    <div>
      <MarathonRoute />
    </div>
  );
}

export default App;
