import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MarathonHome from './components/MarathonHome'

const MarathonRoute = () => {
  return (
    <div>
      <Routes>
          <Route path='/' element={<MarathonHome />} />
      </Routes>
    </div>
  )
}

export default MarathonRoute