import { Card, CardContent, CardMedia, Grid, Grid2 } from '@mui/material'
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import React, { useEffect } from 'react'
import MarathonNavbar from '../layouts/MarathonNavbar';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import $ from "jquery";
import i18n from '../../i18n/config';
import { GrLanguage } from "react-icons/gr";
import { FaFacebook, FaTwitter, FaInstagram, FaPinterest, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const MarathonHome = () => {
    const { register, setValue } = useForm({})
    const { t, i18n } = useTranslation();

    useEffect(() => {
        let lang = localStorage.getItem('lang')
        if (lang === null) {
            setValue('lange', 'ta')
        } else {
            setValue('lange', lang)
        }
    }, [])

    const lang = localStorage.getItem('lang')
    useEffect(() => {
        const savedLang = localStorage.getItem('lang') || 'ta';
        setValue('lange', savedLang);
        i18n.changeLanguage(savedLang);
        document.documentElement.lang = savedLang;
    }, [setValue, i18n]);

    const changeLanguage = (e) => {
        const selectedLang = e.target.value;
        i18n.changeLanguage(selectedLang);
        localStorage.setItem('lang', selectedLang);
        document.documentElement.lang = selectedLang;
    };

    const cardData = [
        {
            title: `${t("Homepage.event3")}`,
            subtitle: `${t("Homepage.eventtitle3")}`,
            description: `${t("Homepage.description2")}`,
            image: "/images/event-1.png",
            date: "NOVEMBER 2, 2018",
            location: "Los Angeles"
        },
        {
            title: `${t("Homepage.event2")}`,
            subtitle: `${t("Homepage.eventtitle2")}`,
            description: `${t("Homepage.description1")}`,
            image: "/images/event-2.png",
            date: "NOVEMBER 15, 2018",
            location: "Los Angeles"
        },
        {
            title: `${t("Homepage.event1")}`,
            subtitle: `${t("Homepage.eventtitle1")}`,
            description: `${t("Homepage.description")}`,
            image: "/images/event-3.png",
            date: "DECEMBER 2, 2018",
            location: "Los Angeles"
        },
    ];

    return (
        <div>
            <section className='home-section'>
                <form className='language-form'>
                    <GrLanguage className='lang-icon' />
                    <select
                        className='lag_select w-100 ml-1 c-pointer'
                        {...register("lange")}
                        onChange={changeLanguage}
                    >
                        <option value='en'>English</option>
                        <option value='ta'>தமிழ்</option>
                    </select>
                </form>

                <div className='logo-section'>
                    <div className='logos-1'>
                        <img src="/images/Tamiltadam.jpg" alt="" className='tamil-logo' />
                    </div>
                    <div className='logos-2'>
                        <img src="/images/Tamil1.jpg" alt="" className='tamil-logo1 img-fluid' />
                    </div>
                </div>
            </section>

            <section>
                <div className='register-evented'>
                    <Grid container spacing={2} justifyContent="center" alignItems="center" className='regs-list'>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className='regs-list-item'>
                            <div className='register-event'>
                                <div><FaCalendarAlt className='register-icon' /></div>
                                <div><span className='registers'>{t("Homepage.date")}</span></div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className='regs-list-item'>
                            <div className='register-event' >
                                <div><FaRegClock className='register-icon' /></div>
                                <div><span className='registers'>{t("Homepage.time")}</span></div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <div className='register-event'>
                                <div><FaLocationDot className='register-icon' /></div>
                                <div><span className='registers'>{t("Homepage.location")}</span></div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <div className='register-event4'>
                                <div><IoArrowForwardCircleOutline className='register-icon1' /></div>
                                <div><span className='registered'>{t("Homepage.register")}</span></div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div>
                                <img src='/images/run.png' alt='' className='bigstock' />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='register-sectors'>
                                <h2 className='register-title'><span className=''>{t("Homepage.title")} </span> - 2024</h2>
                                <p className='register-subtitle'>{t("Homepage.subtitle")}</p>
                                <p className='register-maintitle'>{t("Homepage.maintitle")}</p>
                                <p className='register-maintitle'>{t("Homepage.mainpara")}</p>
                                <div className='register-sectoreds'>
                                    <button className='miss-btn'>{t("Homepage.register")}<MdOutlineKeyboardDoubleArrowRight className='arow-right' /></button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>

            <section className='event-section'>
                <div className='event-card-sect'>
                    <Grid2 container spacing={3} justifyContent="center">
                        {cardData.map((card, index) => (
                            <Grid2 item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                                <div>
                                    <h2 className='events-title'><span className=''>{card.title}</span> {card.subtitle}</h2>
                                </div>
                                <Card sx={{ maxWidth: 400 }} className={lang === "en" ? "card-container" : "card-container1"} >
                                    <div>
                                        <CardMedia
                                            component="img"
                                            height="180%"
                                            image={card.image}
                                            alt={card.title}
                                        />
                                    </div>
                                    <CardContent className="card-context">
                                        <p className="carded-subtitle">{card.description}</p>
                                    </CardContent>
                                </Card>
                            </Grid2>
                        ))}
                    </Grid2>
                </div>
            </section>

            <section>
                <div className='events-images'>
                    <div className='even-image'><img src='/images/mara-1.png' alt='' className='eve-list' /></div>
                    <div className='even-image'><img src='/images/mara-6.png' alt='' className='eve-list' /></div>
                    <div className='even-image'><img src='/images/mara-2.png' alt='' className='eve-list' /></div>
                    <div className='even-image'><img src='/images/mara-3.png' alt='' className='eve-list' /></div>
                    <div className='even-image'><img src='/images/mara-4.png' alt='' className='eve-list' /></div>
                    <div className='even-image'><img src='/images/mara-5.png' alt='' className='eve-list' /></div>
                </div>
            </section>

            <section className='underline-content'>
                <div>
                    <h2 className='underline-title'><span className=''>{t("Homepage.sponsors")}</span></h2>
                </div>
                <div className='underline-images'>
                    <h1>{t("Homepage.coming")}...</h1>
                </div>
            </section>

            <section className='underline-content'>
                <div>
                    <h2 className='underline-title'><span className=''>{t("Homepage.partners")}</span></h2>
                </div>
                <div className='underline-images'>
                    <div className='under-image'><img src='/images/logo.png' alt='' className='logo-list' /></div>
                    <div className='under-imaged'><img src='/images/Aniyam-Eng-2.png' alt='' className='logo-list' /></div>
                </div>
            </section>

            <section className='marathon-footer1'>
                <div className='marathon-section'>
                    <h2 className='marathon-title'>#{t("Homepage.title")}<span className='marathon-num'> 2024</span></h2>
                    <div className="marathon-social-icons-wrapper">
                        <div className="marathon-socials-links">
                            <Link href="#/" target='_blank' className="marathon-socials-icon"><FaFacebook /></Link>
                            <Link href="#/" target='_blank' className="marathon-socials-icon"><FaTwitter /></Link>
                            <Link href="#/" target='_blank' className="marathon-socials-icon"><FaInstagram /></Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className='marathon-footer3'>
                {lang === "en" ? (
                    <div className='marathon-section'>
                        <p className='marathon-last'>{t("Homepage.copyrights")} <span><img src="/images/Amizhth.png" alt="" className='amizhth' /></span></p>
                    </div>
                ) : (
                    <div className='marathon-section'>
                        <p className='marathon-last'><span><img src="/images/Amizhth.png" alt="" className='amizhth' /></span> {t("Homepage.copyrights")}</p>
                    </div>
                )
                }
            </section>
        </div>
    )
}

export default MarathonHome